import React, { useState } from "react";
import Layout from "../components/layout";
import hash from "hash.js";
import { jsx, css } from "@emotion/react";
import { isEmpty } from "lodash";
import axios from "axios";
import styled from "@emotion/styled";
import { API_URL } from "../../env";

const colors = { bad: "#ffced2" };

const isValidUrl = (url) =>
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url
  );

const isOnlyNumber = (str) => /^\d+$/.test(str);

const Input = styled.input({
  border: "1px solid gray",
  borderRadius: "5px",
  padding: "0.4rem",
  display: "block",
});

const Form = styled.form({
  margin: "10rem auto",
  maxWidth: "400px",
});

const Option = styled.div({
  display: "flex",
  marginTop: "2rem",
  justifyContent: "space-between",
  alignItems: "center",
});

const Button = styled.button({
  marginTop: "2rem",
});

function AddBlogForm() {
  const [query, setQuery] = useState({
    title: "",
    url: "",
    feedUrl: "",
    creator: "",
    country: "",
    category: "",
    tags: "",
    date: "",
  });
  const [validation, setValidation] = useState({
    title: true,
    url: true,
    feedUrl: true,
    creator: true,
    country: true,
    category: true,
    tags: true,
    date: true,
  });
  const [response, setResponse] = useState("");

  const url = `${API_URL}/submit/?title=${query.title}&url=${
    query.url
  }&feedUrl=${query.feedUrl}&creator=${query.creator}&country=${
    query.country
  }&category=${query.category}&tags=${query.tags}&date=${new Date(query.date)}`;

  return (
    <div
      css={{
        direction: "rtl",
      }}
    >
      <Form
        onSubmit={async (event) => {
          event.preventDefault();
          try {
            const res = await axios({
              method: "get",
              url: url,
              headers: {
                Authorization: "Basic ZWxmZWhyZXN0Ok5qNG1NSlEwd0Z6MUJTakR6THQz",
              },
            });
            setResponse(res.data.msg);
            if (res.data.status == 201) {
              setQuery({
                title: "",
                url: "",
                feedUrl: "",
                creator: "",
                country: "",
                category: "",
                tags: "",
                date: "",
              });
              setValidation({
                title: true,
                url: true,
                feedUrl: true,
                creator: true,
                country: true,
                category: true,
                tags: true,
                date: true,
              });
            }
          } catch (error) {
            setResponse(error);
          }
        }}
      >
        <h3>أضف مدوّنة جديدة</h3>
        <Option css={{}}>
          <label>عنوان المدوّنة</label>
          <Input
            type="text"
            placeholder={"عنوان المدوّنة"}
            value={query.title}
            onChange={(event) =>
              setQuery({ ...query, title: event.target.value })
            }
            onBlur={(event) =>
              isEmpty(event.target.value)
                ? setValidation({ ...validation, text: false })
                : setValidation({ ...validation, text: true })
            }
          />
        </Option>
        <Option>
          <label>رابط المدوّنة</label>
          <Input
            type="text"
            placeholder={"https://example.com"}
            value={query.url}
            onChange={(event) =>
              setQuery({ ...query, url: event.target.value })
            }
            onBlur={(event) =>
              isValidUrl(event.target.value)
                ? setValidation({ ...validation, url: true })
                : setValidation({ ...validation, url: false })
            }
            css={{ border: validation.url || `2px solid ${colors.bad}` }}
          />
        </Option>
        <Option>
          <label>رابط الخلاصات</label>
          <Input
            type="text"
            placeholder={"https://example.com/feed"}
            value={query.feedUrl}
            onChange={(event) =>
              setQuery({ ...query, feedUrl: event.target.value })
            }
            onBlur={(event) =>
              isValidUrl(event.target.value)
                ? setValidation({ ...validation, feedUrl: true })
                : setValidation({ ...validation, feedUrl: false })
            }
            css={{
              border: validation.feedUrl || `2px solid ${colors.bad}`,
            }}
          />
        </Option>
        <Option>
          <label>المدوّن</label>
          <Input
            type="text"
            placeholder={"اسم المدون"}
            value={query.creator}
            onChange={(event) =>
              setQuery({ ...query, creator: event.target.value })
            }
          />
        </Option>

        <Option>
          <label>البلد</label>
          <Input
            type="text"
            placeholder={"البلد الأم للمدوّن"}
            value={query.country}
            onChange={(event) =>
              setQuery({ ...query, country: event.target.value })
            }
          />
        </Option>
        <Option>
          <label>التصنيف</label>
          <Input
            type="text"
            placeholder={"التصنيف"}
            value={query.category}
            onChange={(event) =>
              setQuery({ ...query, category: event.target.value })
            }
          />
        </Option>
        <Option>
          <label>الوسوم</label>
          <Input
            type="text"
            placeholder={"وسم، وسم، وسم"}
            value={query.tags}
            onChange={(event) =>
              setQuery({ ...query, tags: event.target.value })
            }
          />
        </Option>
        <Option>
          <label>سنة الإنطلاق</label>
          <Input
            type="text"
            placeholder={"2005"}
            value={query.date}
            onChange={(event) =>
              setQuery({ ...query, date: event.target.value })
            }
            onBlur={(event) =>
              isOnlyNumber(event.target.value)
                ? setValidation({ ...validation, date: true })
                : setValidation({ ...validation, date: false })
            }
            css={{ border: validation.date || `2px solid ${colors.bad}` }}
          />
        </Option>
        <Button type="submit">أرسل</Button>
        {response}
      </Form>
    </div>
  );
}

async function validateLogin(hash) {
  const res = await axios({
    method: "get",
    url: `${API_URL}/login/?hash=${hash}`,
    headers: {
      Authorization: "Basic ZWxmZWhyZXN0Ok5qNG1NSlEwd0Z6MUJTakR6THQz",
    },
  });
  return res.data.status;
}

function Login({ setisLoggedIn }) {
  const [username, setUsername] = useState("");
  const [passHash, setPassHash] = useState("");

  return (
    <div>
      <Form
        onSubmit={async (event) => {
          event.preventDefault();
          if (username != "tareef") return false;
          (await validateLogin(passHash)) === 200
            ? setisLoggedIn(true)
            : setisLoggedIn(false);
        }}
      >
        <h3>تسجيل الدخول</h3>
        <Option>
          <label>اسم المستخدم</label>
          <Input
            type="text"
            placeholder="اسم المتسخدم"
            onBlur={(e) => setUsername(e.target.value)}
          />
        </Option>
        <Option>
          <label>كلمة المرور</label>
          <Input
            type="password"
            placeholder="كلمة المرور"
            onBlur={(e) =>
              setPassHash(hash.sha256().update(e.target.value).digest("hex"))
            }
          />
        </Option>
        <Button type="submit">أرسل</Button>
      </Form>
    </div>
  );
}

function AdminPanel() {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  return (
    <Layout>
      {isLoggedIn || <Login setisLoggedIn={setisLoggedIn} />}
      {isLoggedIn && <AddBlogForm />}
    </Layout>
  );
}

export default AdminPanel;
